import React from 'react';
import './office-on-map.css'
import {Container, Row, Col } from 'reactstrap';
import {Link} from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { faVk, faInstagram, faFacebook} from '@fortawesome/free-brands-svg-icons';

const OfficeOnMap = () => {
  return (
    <Container fluid className="cont-office-on-map mt-5">
        <Row className="pt-3 font_white">
            <Col md="4" xs="12" className="p-4">
                <div>
                    <h4 className="h4 align-center font-green">Адрес:</h4>

                    <p className="font-green pt-2">Калининградская обл.</p>
                    <p className="font-green">Гвардейский р-н </p>
                    <p className="font-green">пос. Куйбышевское</p>
                    <a href="tel:+79814572668" className="contacts-tel font-green" title="">+7 981 457 26 68</a>
                    <span className="separator-fullScreen"></span>
                    <Row>
                            <a className="m-1" rel="nofollow" target="_blank" href="https://www.instagram.com/plemcentre/">
                                <div className="social-icon">
                                    <FontAwesomeIcon className="svg-style"
                                            icon={faInstagram}
                                            color="#c5ad70"
                                            size="lg"
                                            />
                                </div>
                            </a>
                            <a className="m-1" target="_blank" target="_blank" href="https://vk.com/plemcentre">
                                    <div className="social-icon">
                                        <FontAwesomeIcon className="svg-style"
                                                icon={faVk}
                                                color="#c5ad70"
                                                size="lg" 
                                                />
                                    </div>
                            </a>
                            <a className="m-1" target="_blank" target="_blank" href="https://www.facebook.com/plemcentre/">
                                    <div className="social-icon">
                                        <FontAwesomeIcon className="svg-style"
                                                icon={faFacebook}
                                                color="#c5ad70"
                                                size="lg" 
                                                />
                                    </div>
                            </a>
                    </Row>
                   


                   
                    
                        
                </div>
            </Col>
            <Col md="8" xs="12" >
                <iframe name="Yandex map" title="Yandex map" className="p-3 rounded" src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae003a9b7152fcf67cf320968381614c95a97dbb499cf8d58e98a4e5b93796f3e&amp;source=constructor" width="100%" height="100%" frameBorder="10"></iframe>
            </Col>
        </Row>
    </Container>
  );
};

export default OfficeOnMap;