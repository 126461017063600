import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import TopBunerProduct from '../../components/top-buner/top-baner-product';
import OfficeAtMap from '../../components/office-on-map/office-on-map';
import ImagesView from '../../components/images-view/images-view';
import FormQuickOrder from '../../components/form-quick-oreder/form-quick-order';
import AlpinGoatfeature from '../../images/sheeps/frizskaya_poroda_ovec/frizskaia-ovcia-feature.jpg'
import AlpinGoatGallery1 from '../../images/sheeps/frizskaya_poroda_ovec/frizkie-ovci-v-zagone.jpg'
import AlpinGoatGallery2 from '../../images/sheeps/frizskaya_poroda_ovec/frizkie-plemennie-ovcy.jpg'
import AlpinGoatGallery3 from '../../images/sheeps/frizskaya_poroda_ovec/frizskiayaia-ovca.jpg'
import AlpinGoatGallery4 from '../../images/sheeps/frizskaya_poroda_ovec/frizskie-ovcy-chernogo-okrasa.jpg'
import AlpinGoatGallery5 from '../../images/sheeps/frizskaya_poroda_ovec/frozskie-yagniata.jpg'
import AlpinGoatGallery6 from '../../images/sheeps/frizskaya_poroda_ovec/plemennie-vostochno-frizskie-ovcy.jpg'
import AlpinGoatGallery7 from '../../images/sheeps/frizskaya_poroda_ovec/frizskiy.jpg'


const AlpinePoroda = (props) => {
  const breadCramb = [{name:'Главная', url:'/', status: false}, 
    {name:'Описание Восточно-Фризской породы овец', url:'#', status:true}]

    const indicators = [
    
  ]

    const galleryImage = [
      {
        src: AlpinGoatGallery1,
        width: 3,
        height: 2,
        alt: 'Восточно-Фризская овца',
        title: 'Восточно-Фризская овца'
      },
      {
        src: AlpinGoatGallery2,
        width: 3,
        height: 2,
        alt: 'Восточно-Фризская овца',
        title: 'Восточно-Фризская овца'
      },
      {
        src: AlpinGoatGallery3,
        width: 3,
        height: 2,
        alt: 'Восточно-Фризская овца',
        title: 'Восточно-Фризская овца'
      },
      {
        src: AlpinGoatGallery4,
        width: 3,
        height: 2,
        alt: 'Восточно-Фризская овца',
        title: 'Восточно-Фризская овца'
      },
      {
        src: AlpinGoatGallery5,
        width: 3,
        height: 2,
        alt: 'Восточно-Фризская овца',
        title: 'Восточно-Фризская овца'
      },
      {
        src: AlpinGoatGallery6,
        width: 3,
        height: 2,
        alt: 'Восточно-Фризская овца',
        title: 'Восточно-Фризская овца'
      }
      ,
      {
        src: AlpinGoatGallery7,
        width: 3,
        height: 2,
        alt: 'Восточно-Фризская овца',
        title: 'Восточно-Фризская овца'
      }
    ];
  return (
    <div>
        <SEO title="Восточно Фризская Порода Овец: описание породы, цена, фото"
            description="Восточно-Фризская порода овец была названа по месту выведения в Восточной Фрисландии на севере Германии и Голландии.">                          
      </SEO>
      <Layout>
      <TopBunerProduct TopImage={''}  
                      titileH1={'Описание Восточно-Фризской породы овец'}
                      comment={""}
                      FormView={true}
                      LogoView={false}
                      typeofBunner={'descrition-page'}
                      breadCrumbs={breadCramb}
                      indicators={indicators}>
            </TopBunerProduct>
        <Container fluid>
         <Row className="mt-5">
              <Col md="6" xs='12' className="text-justify ">
                <div className="pl-md-5">
                    <h1 className="text-center text-md-left ">Восточно-Фризская порода овец</h1>
                    <p className="text-justify">
                      Восточно-Фризская порода овец была названа по месту выведения в Восточной Фрисландии на севере Германии и Голландии. 
                      Это лучшая или одна из лучших пород овец с точки зрения молочной продуктивности. Существует три окраса: белый, 
                      черный и черно-белый. В России наибольшей популярностью пользуются белые овцы, хотя черные, как правило, крупнее и сильнее.
                    </p>
                    <p className="text-justify">
                      Овцы покрыты густой и теплой шерстью везде, кроме головы и хвоста. Отличительной особенностью и слабым местом
                      у Восточно-Фризской породы стал этот самый длинный, голый хвост. Средняя продолжительность лактации 250 дней. 
                      Средняя продуктивность за лактацию 400 – 500 кг молока, хотя не редки случаи и 800-900 кг.
                    </p>
                </div>
              </Col>
              <Col md="6" xs='12' className="text-center">
                <img src={AlpinGoatfeature} className="w-100" style={{maxWidth:"500px"}}  
                                                            alt="Фото Восточно-Фризской породы овец" 
                                                            title="Фото Восточно-Фризской породы овец" />
              </Col>
            </Row>
        </Container>
        
         <Container fluid className="mt-3">
           <Row>
             <Col md="6" sm="12" className="pl-md-5">
              <p className="text-justify">
              Еще одним плюсом Восточно-Фризской породы является многоплодность. Фризов используют как в чистоте, 
              так и для скрещивания с другими породами. При скрещивании получают более высокий выход ягнят на матку, 
              повышение молочной продуктивности и более длинную лактацию. В трехпородных мясных кроссах восточно-фризскую 
              породу используют для увеличения многоплодности и получения более крупных и скороспелых животных.
              </p>
            </Col>
            <Col md="6" sm="12" className="pl-md-5">
              <p className="text-justify" className="text-justify">
              Трехпородные мясные кроссы позволяют на местном мелком поголовье овец уже через два года получить мясной тип животных. 
              Аборигенная порода (матки) Х Восточно-Фризская порода (бараны)= увеличение выхода ягнят, более крупные животные. 
              Помесный молодняк (матки) Х Тексель (бараны)= великолепные мясные качества и скороспелость.
              </p>
            </Col>
           </Row>
         </Container>
        <Container fluid className="mt-3">
          <h2 className="text-center">Фото овец Восточно-Фризской породы</h2>
          <ImagesView galleryImage={galleryImage} />
        </Container>
        <OfficeAtMap />
      </Layout>
      
    </div>
  );
};

export default AlpinePoroda;