import React, {Component} from 'react';
import {Link} from 'gatsby';import { Container, Row, Col, Form, FormGroup, Input } from 'reactstrap';
import FarmBunner from '../../images/bunner-for-form.jpg'
import FormOneInput from '../forms/form-one-input'

export default class FormQiuckOrder extends Component {
     
    render() {
  return (
      <div style={{"backgroundImage":`url(${FarmBunner})`, "backgroundAttachment":"fixed",
      "backgroundPosition":"center",}}>
          <Container>
          <h3 className="text-center pt-3 font-white">Ответим на Ваши вопросы</h3>
                {/* <p className="text-center font-white">Оставьте ваши данные, наш специалист свяжется с вами в ближайшее время</p>  */}
              <FormOneInput />
            
        </Container>                        
      </div>
  );
}
}
